import type { MouseEventHandler, ReactNode } from 'react';
import React from 'react';

import ExclamationTriangleIcon from '@heroicons/react/24/outline/ExclamationTriangleIcon';
import VideoCameraSlashIcon from '@heroicons/react/24/outline/VideoCameraSlashIcon';
import VideoCameraIcon from '@heroicons/react/24/solid/VideoCameraIcon';
import type { ButtonProps } from 'atp-react-ui';
import { Button, Text, Tooltip, twMerge, useBoolean } from 'atp-react-ui';
import type { Timeout } from 'react-number-format/types/types';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@/_navigation';
import type { LESSON_STATUS, PrepCourseLessonMeta } from '@/_prep-portal/courses/api/shared';
import { parseLessonInfo } from '@/_prep-portal/courses/api/shared';
import { useLessonNotificationContext } from '@/_prep-portal/courses/courses/components';
import { dateTime } from '@/_shared';

import { type PrepCourseLessonButtonProps } from './types';

const cardInfoBtn = 'justify-between px-4 py-2 shadow-none disabled:border-transparent';

const countdownStatuses: LESSON_STATUS[] = [
  'SCHEDULED',
  'SCHEDULED_TODAY',
  'WITHIN_60',
  'WITHIN_15',
  'LIVE',
  'DELAYED',
];

export function PrepCourseLessonButton({
  id,
  course_id,
  next_start,
  start_time,
  end_time,
  instructor_getting_late,
  cancelled,
  zoom_meeting_id,
  instructor_employee_email,
}: PrepCourseLessonButtonProps) {
  const nav = useNavigate();

  const { setCourseLive, setCourseOffline } = useLessonNotificationContext();

  const timerIdRef = React.useRef<Timeout | null>(null);
  const [intervalTime, setIntervalTime] = React.useState<null | number>(null);

  const getLessonInfo = React.useCallback(
    () =>
      parseLessonInfo({
        next_start: next_start || '',
        start_time,
        end_time,
        instructor_getting_late: instructor_getting_late || '0',
        cancelled,
      }),
    [cancelled, end_time, instructor_getting_late, next_start, start_time]
  );

  const [lessonMeta, setLessonMeta] = React.useState<PrepCourseLessonMeta>(getLessonInfo().meta);
  const [isLive, live] = useBoolean();

  const handleLaunchLesson = React.useCallback(
    async (meetingId: string | null, instructorEmail: string) => {
      if (meetingId) {
        nav(ROUTES.JOIN_LESSON_WAITING.path, {
          state: ROUTES.JOIN_LESSON_WAITING.buildState({
            meetingId,
            email: instructorEmail,
            id,
            instructor_getting_late: instructor_getting_late || '0',
          }),
        });
      }
    },
    [id, instructor_getting_late, nav]
  );

  const handleLessonStatus = React.useCallback(() => {
    const info = getLessonInfo();
    if (info.meta.lessonStatus === 'WITHIN_15') {
      setIntervalTime(1000);
    } else {
      setIntervalTime(60 * 1000);
    }
    setLessonMeta(info.meta);
    live.set(info.liveBadge);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLessonInfo]);

  const renderButton = (
    disabled: boolean,
    className: ButtonProps['className'],
    iconRight: ButtonProps['iconRight'],
    iconClassName: ButtonProps['iconClassName'],
    onClick: MouseEventHandler<HTMLAnchorElement> | undefined,
    title: string,
    description: ReactNode
  ) => (
    <Button
      as="link"
      tag="button"
      disabled={disabled}
      block
      className={twMerge('prep-course-lesson-status-button', className)}
      iconRight={iconRight}
      iconClassName={iconClassName}
      onClick={onClick}
    >
      <div className="flex flex-col items-start text-accent">
        {title}
        <Text as="caption" className="mt-0.5 text-accent-light">
          {description}
        </Text>
      </div>
    </Button>
  );

  React.useEffect(() => {
    const { meta } = getLessonInfo();

    if (meta.lessonStatus && countdownStatuses.includes(meta.lessonStatus)) {
      handleLessonStatus();
    }
  }, [getLessonInfo, handleLessonStatus]);

  React.useEffect(() => {
    if (intervalTime) {
      if (timerIdRef.current) {
        clearInterval(timerIdRef.current);
      }

      timerIdRef.current = setInterval(handleLessonStatus, intervalTime);
    }

    return () => {
      if (timerIdRef.current) {
        clearInterval(timerIdRef.current);
      }
    };
  }, [handleLessonStatus, intervalTime]);

  React.useEffect(() => {
    if (isLive) {
      setCourseLive(course_id);
    } else {
      setCourseOffline(course_id);
    }
  }, [course_id, isLive, setCourseLive, setCourseOffline]);

  const { lessonStatus, statusDescription, localStamps } = lessonMeta;

  switch (lessonStatus) {
    case 'SCHEDULED':
      return renderButton(
        true,
        `${cardInfoBtn} disabled:bg-gray-50`,
        VideoCameraSlashIcon,
        'text-accent-light',
        undefined,
        'Next Lesson',
        statusDescription
      );
    case 'SCHEDULED_TODAY':
      return renderButton(
        true,
        `${cardInfoBtn} disabled:bg-gray-50`,
        VideoCameraSlashIcon,
        'text-accent-light',
        undefined,
        'Next Lesson',
        `Today at ${dateTime.format(localStamps.startTimeDate, 'hh:mmA')}`
      );
    case 'LIVE':
    case 'WITHIN_60':
    case 'WITHIN_15':
      return (
        <Tooltip zIndex={2} content="Join Lesson" placement="top-end">
          {renderButton(
            lessonStatus === 'WITHIN_60',
            `${
              lessonStatus === 'WITHIN_60' ? '' : 'group'
            } justify-between px-4 py-2 shadow-none disabled:border-transparent disabled:bg-gray-50`,
            VideoCameraIcon,
            `${lessonStatus === 'WITHIN_60' ? 'text-accent-light' : 'text-success group-hover:text-primary'}`,
            () => handleLaunchLesson(zoom_meeting_id, instructor_employee_email),
            lessonStatus === 'LIVE' ? 'Your lesson is live' : statusDescription,
            lessonStatus === 'LIVE'
              ? 'Click here to join'
              : `Today at ${dateTime.format(localStamps.startTimeDate, 'hh:mmA')}`
          )}
        </Tooltip>
      );
    case 'DELAYED':
      return renderButton(
        true,
        `${cardInfoBtn} disabled:bg-warning-x-light/60`,
        ExclamationTriangleIcon,
        'text-yellow-600',
        undefined,
        'Lesson delayed',
        'Your instructor is experiencing some difficulties. Please wait.'
      );
    case 'CANCELED':
      return renderButton(
        true,
        `${cardInfoBtn} disabled:bg-error-x-light/60`,
        VideoCameraSlashIcon,
        'text-error',
        undefined,
        'Lesson Canceled',
        `Today at ${dateTime.format(localStamps.startTimeDate, 'hh:mmA')}`
      );
    case 'ENDED':
      return renderButton(
        true,
        `${cardInfoBtn} disabled:bg-gray-50`,
        VideoCameraSlashIcon,
        'text-accent-light',
        undefined,
        'Lesson ended',
        `Today at ${dateTime.format(localStamps.endTimeDate, 'hh:mmA')}`
      );
    default:
      return null;
  }
}

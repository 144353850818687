import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useSetTimeout } from 'atp-react-ui';

import { useMeContext } from '@/_providers/me-provider';
import { checkOnboardingVideoShown, onboardingVideoShown } from '@/_shared';

import { OnboardingContext } from './context';
import type { OnboardingContextType } from './types';

export function OnboardingProvider({ children }: { children: ReactNode }) {
  const [showOnboardingVideo, setShowOnboardingVideo] = useState(false);
  const { setTimeout, clearTimeout } = useSetTimeout();
  const { data } = useMeContext();

  const handleShowVideo = useCallback(() => {
    setShowOnboardingVideo(true);
  }, []);

  const handleHideVideo = useCallback(() => {
    if (!data?.id) {
      return;
    }

    setShowOnboardingVideo(false);
    onboardingVideoShown(data.id);
  }, [data?.id]);

  useEffect(() => {
    if (data?.id && !checkOnboardingVideoShown(data.id)) {
      const id = setTimeout(handleShowVideo, 3000);

      return () => clearTimeout(id);
    }

    return undefined;
  }, [clearTimeout, data?.id, handleShowVideo, setTimeout]);

  const value: OnboardingContextType = useMemo(
    () => ({
      showOnboardingVideo,
      showVideo: handleShowVideo,
      hideVideo: handleHideVideo,
    }),
    [showOnboardingVideo, handleShowVideo, handleHideVideo]
  );

  return <OnboardingContext.Provider value={value}>{children}</OnboardingContext.Provider>;
}

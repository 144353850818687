import InformationCircleIcon from '@heroicons/react/24/solid/InformationCircleIcon';
import { Button } from 'atp-react-ui';
import { Link } from 'react-router-dom';

import type { PrepCourseHeaderProps } from './types';

export function PrepCourseHeader({ id, imageUrl, alt, route }: PrepCourseHeaderProps) {
  return (
    <header className="course-card-article-header border-outline/30">
      {route && (
        <Link to={route.buildPath({ id } as never)}>
          <Button
            as="link"
            icon={InformationCircleIcon}
            iconClassName="size-6"
            aria-label="View course details"
            className="course-card-article-header-btn prep-course-detail-button hover:bg-primary-x-light active:bg-primary-x-light"
          />
        </Link>
      )}
      <img className="h-28 w-full object-cover" src={imageUrl} alt={alt} loading="lazy" />
    </header>
  );
}

import BellIcon from '@heroicons/react/24/outline/BellIcon';
import { Button } from 'atp-react-ui';
import { NavLink } from 'react-router-dom';

import { useAccountDependenciesContext } from '@/_providers';

import { AccountMenu } from './account-menu';
import { useCoursesLinkActive } from './use-courses-link-active';

import type { INavbar } from '.';

function DesktopNav({ secondaryLinks, user }: INavbar) {
  const { isRewardProgramActive, setReferralModal } = useAccountDependenciesContext();
  const isLinkActive = useCoursesLinkActive();

  return (
    <div className="ml-4 hidden md:block">
      <div className="flex items-center gap-x-1">
        {secondaryLinks.map((item) => (
          <NavLink key={item.path} to={item.path}>
            {({ isActive }) => (
              <Button
                as="link"
                size="sm"
                className={`mx-px text-white hover:bg-gray-overlay/30 hover:text-gray-75 focus:ring-0 ${
                  isLinkActive(item, isActive) ? 'bg-accent-light/30 text-primary-medium' : ''
                }`}
              >
                {item.META.name}
              </Button>
            )}
          </NavLink>
        ))}

        {isRewardProgramActive && (
          <Button
            as="link"
            size="sm"
            className="mx-px text-white hover:bg-gray-overlay/30 hover:text-gray-75 focus:ring-0"
            onClick={setReferralModal}
          >
            Refer a friend
          </Button>
        )}
        <Button
          size="sm"
          as="link"
          icon={BellIcon}
          className="ml-2 hidden text-white hover:bg-gray-overlay/30 hover:text-gray-75 focus:ring-0"
        />

        {/* Profile dropdown */}
        <div className="ml-2 inline-block flex-shrink-0 flex-grow-0">
          <AccountMenu user={user} />
        </div>
      </div>
    </div>
  );
}

export default DesktopNav;

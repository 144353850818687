import Bars3Icon from '@heroicons/react/24/outline/Bars3Icon';
import BellIcon from '@heroicons/react/24/outline/BellIcon';
// import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger, Button, useButton } from 'atp-react-ui';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import type { RouteRecordsType } from '@/_navigation/routes';
import ROUTES from '@/_navigation/routes';
import AtpCharLogoIcon from '@/_shared/img/atp-char-logo.svg?react';

import { Container } from '../../_components/layouts';

import { AccountMenu } from './account-menu';
import DesktopNav from './desktop-nav';
import MobileNav from './mobile-nav';

export type NavbarUserInfo = {
  name: string;
  email: string;
  imageUrl: string;
  role: string;
};

export interface INavbar {
  secondaryLinks: RouteRecordsType[];
  user: NavbarUserInfo;
}

function Navbar(props: INavbar): JSX.Element {
  const linkBtn = useButton({ as: 'link', className: 'size-9 p-0' });
  const { user } = props;

  return (
    <Accordion type="single" collapsible className="sticky top-0 z-20 w-full bg-accent">
      <AccordionItem value="1">
        <Container className="flex h-16 items-center justify-between pl-6 pr-2">
          <Link
            to={ROUTES.IN_PROGRESS.path}
            className={twMerge(linkBtn.buttonStyles, 'hover:bg-transparent focus:ring-0')}
          >
            <span className="sr-only">Sales Portal logo</span>
            <AtpCharLogoIcon className="size-8 transform-gpu transition-all duration-300 ease-in-out hover:skew-x-3 hover:scale-110" />
          </Link>

          <DesktopNav {...props} />

          <div className="flex flex-row-reverse items-center gap-x-2 p-0 md:hidden">
            <AccordionTrigger className="p-0">
              <Button tag="div" icon={Bars3Icon} />
            </AccordionTrigger>
            <div className="flex items-center">
              <AccountMenu user={user} />
            </div>
            <div>
              <Button
                size="sm"
                as="link"
                icon={BellIcon}
                className="ml-2 hidden text-white hover:bg-gray-overlay/30 hover:text-gray-75 focus:ring-0"
              />
            </div>
          </div>
        </Container>
        <AccordionContent>
          <MobileNav {...props} />
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}

export default Navbar;

import { twMerge } from 'tailwind-merge';

import { PageHead, useStickyHeaderContext } from '@/_components';

export function HeadersTabWrapper({
  children,
  headerClassName,
  rightSection,
  className,
}: {
  children: React.ReactNode;
  headerClassName?: string;
  rightSection?: React.ReactNode;
  className?: string;
}) {
  const { isSticky } = useStickyHeaderContext();
  return (
    <PageHead
      headerClassName={headerClassName}
      className={twMerge(
        'flex-col items-start gap-x-3 border-b md:flex-row',
        isSticky ? 'border-transparent' : 'border-outline',
        className
      )}
    >
      <nav className="-mb-px flex space-x-2">{children}</nav>
      {rightSection}
    </PageHead>
  );
}

import { useSuspenseQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { apiClient, queryClient } from '@/_services';

import { getCourseImage } from './shared';

const instructorInfoSchema = z.object({
  instructor_name: z.string(),
  instructor_job_title: z.string(),
  instructor_department: z.string(),
  instructor_team: z.string().nullable(),
  instructor_educational_background: z.string(),
  instructor_image_url: z.string().nullable(),
  instructor_bio: z.string(),
});

export type InstructorInfoSchema = z.infer<typeof instructorInfoSchema>;

const ScheduledClassSchema = instructorInfoSchema.extend({
  day_s_of_the_week: z.string(),
  end_date: z.string(),
  end_time: z.string(),
  id: z.string(),
  start_date: z.string(),
  start_time: z.string(),
  time_formula: z.string(),
  employee_id: z.string(),
});

export type ScheduledClassType = z.infer<typeof ScheduledClassSchema>;

const eligibleScheduledClassSchema = z.object({
  next_start: z.string().nullable(),
  no_of_eligible_scheduled_classes: z.number(),
  scheduled_classes: z.array(ScheduledClassSchema),
});

const unregisteredCourseSchema = z.object({
  id: z.string(),
  name: z.string(),
  length: z.string(),
  description: z.string(),
  eligible_scheduled_classes: eligibleScheduledClassSchema,
  exam_standard: z.string(),
  ranking: z.string(),
  study_guide_applied: z.boolean(),
  e_book: z.boolean(),
  manual_revisions: z.string(),
  non_atp_study_guide: z.boolean(),
  is_in_house: z.boolean(),
  check_completed_courses: z.boolean(),
  nursing_question_text: z.string().nullable(),
  enrollment_id: z.string(),
  nursing_question_s_answer: z.unknown().nullable(),
  course_image: z.string(),
});

export type UnregisteredCourseSchema = z.infer<typeof unregisteredCourseSchema>;

const UnregisteredCoursesApiResponseSchema = z.array(unregisteredCourseSchema);

export type UnregisteredCoursesApiResponseType = z.infer<typeof UnregisteredCoursesApiResponseSchema>;

export const UNREGISTERED_COURSES_QUERY_KEY = ['UNREGISTERED_COURSES'];

export const useUnregisteredCoursesQuery = () =>
  useSuspenseQuery<UnregisteredCoursesApiResponseType, unknown, UnregisteredCoursesApiResponseType>({
    queryKey: UNREGISTERED_COURSES_QUERY_KEY,
    queryFn: async () =>
      apiClient.request({
        endpoint: '/enrollments/',
        responseSchema: UnregisteredCoursesApiResponseSchema,
      }),
    select: (data) =>
      data.map((course) => ({
        ...course,
        course_image: getCourseImage(course.name),
      })),
  });

export const invalidateUnregisteredCoursesQuery = () =>
  queryClient.invalidateQueries({ queryKey: UNREGISTERED_COURSES_QUERY_KEY });

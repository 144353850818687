import { Skeleton } from 'atp-react-ui';
import { Outlet } from 'react-router-dom';

import { Container, StickyPageHeader } from '@/_components';
import { HeadersTabWrapper } from '@/_containers';

export default function Loading() {
  return (
    <>
      <StickyPageHeader threshold={30} className="top-9 pb-0">
        <Container>
          <HeadersTabWrapper>
            {[0, 1, 2].map((tab) => (
              <div key={tab} className="py-2">
                <Skeleton className="h-8 w-28" />
              </div>
            ))}
          </HeadersTabWrapper>
        </Container>
      </StickyPageHeader>
      <Outlet />
    </>
  );
}

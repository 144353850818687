import { FullModal } from '@/_components';
import { useOnboardingContext, useWalkthroughContext } from '@/_providers';

export function OnboardingModal() {
  const { startWalkthrough } = useWalkthroughContext();
  const { showOnboardingVideo, hideVideo } = useOnboardingContext();

  const onClose = () => {
    hideVideo();
    startWalkthrough('COURSE_TABS');
  };

  return (
    <FullModal open={showOnboardingVideo} onClose={onClose} className="md:max-w-5xl" disableInternalClosing>
      <video
        width="100%"
        height="100%"
        controls
        preload="auto"
        src="https://player.vimeo.com/progressive_redirect/playback/913038123/rendition/720p/file.mp4?loc=external&signature=479ac95090d91b9c26a193a821c37acf10f4f0e2913b5199f6adc7c9c90145a2"
      />
    </FullModal>
  );
}

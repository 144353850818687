/* eslint-disable prefer-rest-params */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { config } from '@/_config';

const isAllowed = config.isProduction;
const notAllowedSlugs = ['/why-bridge', '/join-lesson', '/join-lesson-waiting'];

declare global {
  interface Window {
    readyState: 'loaded' | 'complete';
    CXoneDfo?: any;
    cxone?: any;
  }
}
let done = false;

export function initChat() {
  if (!isAllowed) {
    return;
  }
  const initChatWidget = () => {
    window.cxone('chat', 'setPositionX', 'right');
    window.cxone(
      'chat',
      'setCustomCss',
      `[data-selector="HEADER"],
       [data-selector="HEADER"] [aria-label="More"],
       [data-selector="HEADER"] [aria-label="Minimize"], 
       [data-selector="WIDGET"] { color: #ffffff;}
       [data-selector="WIDGET"] { width:64px !important; height:64px !important; }
       [data-selector="CONTENT"] [role="alert"] { padding: 40px 16px 16px; }
       [data-selector="CONTENT"] [data-selector="PRIMARY_BUTTON"] > div { color: #ffffff !important; }`
    );
  };

  (function (n: keyof typeof window, u: string) {
    window.CXoneDfo = n;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    window[n] =
      window[n] ||
      function () {
        (window[n].q = window[n].q || []).push(arguments);
      };
    window[n].u = u;
    const e = document.createElement('script');
    e.type = 'module';
    e.src = `${u}?${Math.round(Date.now() / 1e3 / 3600)}`;
    e.onload = () => {
      if (!done && (!window.readyState || window.readyState === 'loaded' || window.readyState === 'complete')) {
        done = true;
        initChatWidget();
        setTimeout(() => {
          initChatWidget();
          setTimeout(() => {
            initChatWidget();
            setTimeout(() => {
              initChatWidget();
            }, 100);
          }, 100);
        }, 100);

        const found = notAllowedSlugs.find((item) => window.location.pathname.indexOf(item) > -1);

        if (found) {
          hideChatButton();
        }
      }
    };

    e.addEventListener('onreadystatechange', e.onload);
    document.head.appendChild(e);
  })('cxone', 'https://web-modules-de-na1.niceincontact.com/loader/1/loader.js');

  window.cxone('init', '4273');
  window.cxone('chat', 'init', 4273, 'chat_7cb52cc8-4975-47c9-9d9f-82a9503885aa');
}

export const setChatEmail = (_: string) => {
  try {
    // if(done){
    // window.cxone('chat', 'setCustomerEmail', email);}
  } catch (e) {
    console.log(e);
  }
};

export const setChatUsername = (username: string) => {
  try {
    if (done) {
      window.cxone('chat', 'setCustomerName', username);
    }
  } catch (e) {
    console.log(e);
  }
};

export const setChatNameEmail = (email: string, username: string) => {
  try {
    setChatEmail(email);
    setChatUsername(username);
  } catch (e) {
    console.log(e);
  }
};

export const clearChat = () => {};

export const hideChatButton = () => {
  try {
    if (done) {
      window.cxone('chat', 'hideChatWindow');
      const btn = document.getElementById('be-chat-container');
      if (btn) {
        btn.style.display = 'none';
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export function showChatButton() {
  try {
    if (done) {
      const btn = document.getElementById('be-chat-container');
      if (btn) {
        btn.style.display = 'block';
      }
    }
  } catch (e) {
    console.log(e);
  }
}

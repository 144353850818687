import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useMeContext } from '@/_providers/me-provider';
import { checkOnboardingVideoShown } from '@/_shared';

import { WalkthroughContext } from './context';
import type { WalkthroughContextType, WalkthroughType, Walkthroughs } from './types';

export function WalkthroughProvider({ children }: { children: ReactNode }) {
  const [walkthroughs, setWalkthroughs] = useState<Walkthroughs>({});

  const { data } = useMeContext();

  const setWalkthroughViewed = useMemo(() => setWalkthroughViewedHoc(data?.id || ''), [data?.id]);
  const getWalkthroughViewed = useMemo(() => getWalkthroughViewedHoc(data?.id || ''), [data?.id]);

  const handleWalkthroughViewed = useCallback(
    (walkthroughType: WalkthroughType) => {
      setWalkthroughViewed(walkthroughType);
      setWalkthroughs((prev) => ({ ...prev, [walkthroughType]: { ...prev[walkthroughType], show: false } }));
    },
    [setWalkthroughViewed]
  );

  const startWalkthrough = useCallback(
    (walkthroughType: WalkthroughType) => {
      if (getWalkthroughViewed(walkthroughType)) {
        return;
      }

      setWalkthroughs((prev) => ({ ...prev, [walkthroughType]: { type: walkthroughType, show: true } }));
    },
    [getWalkthroughViewed]
  );

  useEffect(() => {
    if (!data) {
      return;
    }

    const _walkthroughs: Walkthroughs = {};

    _walkthroughs.COURSE_TABS = {
      type: 'COURSE_TABS',
      show: !getWalkthroughViewed('COURSE_TABS') && checkOnboardingVideoShown(data.id),
    };

    _walkthroughs.CLASS_SCHEDULE = {
      type: 'CLASS_SCHEDULE',
      show: !getWalkthroughViewed('CLASS_SCHEDULE'),
    };

    _walkthroughs.LESSON_LIVE_INDICATOR = {
      type: 'LESSON_LIVE_INDICATOR',
      show: !getWalkthroughViewed('LESSON_LIVE_INDICATOR'),
    };

    setWalkthroughs(_walkthroughs);
  }, [data, getWalkthroughViewed]);

  useEffect(() => {
    if (!data?.id) {
      return;
    }

    if (checkOnboardingVideoShown(data.id) && walkthroughs.COURSE_TABS && walkthroughs.COURSE_TABS.show === false) {
      const _walkthroughs: Walkthroughs = {};

      _walkthroughs.PREP_COURSE = {
        type: 'PREP_COURSE',
        show: !getWalkthroughViewed('PREP_COURSE'),
      };

      if (data.total_registered_count === 0) {
        _walkthroughs.PREP_COURSE_REGISTRATION = {
          type: 'PREP_COURSE_REGISTRATION',
          show: !getWalkthroughViewed('PREP_COURSE_REGISTRATION'),
        };
      }

      _walkthroughs.PASS_COURSE = {
        type: 'PASS_COURSE',
        show: !getWalkthroughViewed('PASS_COURSE'),
      };

      setWalkthroughs((prev) => ({ ...prev, ..._walkthroughs }));
    }
  }, [walkthroughs.COURSE_TABS, data?.id, data?.total_registered_count, getWalkthroughViewed]);

  const value: WalkthroughContextType = useMemo(
    () => ({
      walkthroughs,
      setWalkthroughViewed: handleWalkthroughViewed,
      startWalkthrough,
    }),
    [walkthroughs, startWalkthrough, handleWalkthroughViewed]
  );

  return <WalkthroughContext.Provider value={value}>{children}</WalkthroughContext.Provider>;
}

function setWalkthroughViewedHoc(userId: string) {
  return (type: WalkthroughType) => localStorage.setItem(`walkthrough-viewed-${userId}-${type}`, 'true');
}

function getWalkthroughViewedHoc(userId: string) {
  return (type: WalkthroughType) => !!localStorage.getItem(`walkthrough-viewed-${userId}-${type}`);
}

import { lazy, Suspense } from 'react';

import Loading from './loading';

const Layout = lazy(() => import('./layout'));

export default function Course() {
  return (
    <Suspense fallback={<Loading />}>
      <Layout />
    </Suspense>
  );
}

import type { ReactNode } from 'react';

import CloseIcon from '@heroicons/react/24/outline/XMarkIcon';
import type { ModalProps } from 'atp-react-ui';
import { Button, Modal, twMerge } from 'atp-react-ui';

type FullModalProps = Omit<ModalProps, 'hideCloseButton' | 'children' | 'unmount' | 'onClose'> & {
  children: ReactNode;
  onClose: () => void;
};

export function FullModal({ children, className, onClose, ...props }: FullModalProps) {
  return (
    <Modal
      {...props}
      hideCloseButton
      className={twMerge('w-3/4 min-w-0 self-center rounded-md !p-0', className)}
      onClose={onClose}
    >
      <ModalCloseButton onClick={onClose} />
      {children}
    </Modal>
  );
}

function ModalCloseButton({ onClick }: { onClick: () => void }) {
  return (
    <Button
      size="md"
      onClick={onClick}
      iconClassName="text-white"
      className="absolute right-4 top-4 z-10 bg-gray-900 px-2 hover:bg-gray-500"
      icon={CloseIcon}
    />
  );
}

import { useSuspenseQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { queryClient, apiClient } from '@/_services';

const aodCourseSchema = z.object({
  id: z.string(),
  name: z.string(),
  length: z.string(),
  scheduled_class: z.string(),
  description: z.string(),
  exam_standard: z.unknown().nullable(),
  ranking: z.string(),
});

export type AodCourseSchema = z.infer<typeof aodCourseSchema>;

const unregisteredAodCoursesApiResponseSchema = z.array(aodCourseSchema);
export type UnregisteredAodCoursesApiResponseType = z.infer<typeof unregisteredAodCoursesApiResponseSchema>;

export const UNREGISTERED_AOD_COURSES_QUERY_KEY = ['UNREGISTERED_AOD_COURSES'];

export const useUnregisteredAodCoursesQuery = () =>
  useSuspenseQuery<UnregisteredAodCoursesApiResponseType>({
    queryKey: UNREGISTERED_AOD_COURSES_QUERY_KEY,
    queryFn: async () =>
      apiClient.request({
        endpoint: '/aod-enrollments/',
        responseSchema: unregisteredAodCoursesApiResponseSchema,
      }),
  });

export const invalidateUnregisteredAodCoursesQuery = () =>
  queryClient.invalidateQueries({
    queryKey: UNREGISTERED_AOD_COURSES_QUERY_KEY,
  });

import { Skeleton } from 'atp-react-ui';

import { TableCell } from '../layouts';

import { scheduledTimes } from './model';

export function ClassSchedulesLoading() {
  return (
    <table className="min-w-full border-separate" style={{ borderSpacing: 0 }}>
      <thead>
        <tr className="bg-gray-50">
          <th
            scope="col"
            className="sticky top-0 z-10 border-b border-outline bg-gray-50 py-3.5 pl-4 pr-3 text-left backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
          >
            <Skeleton className="h-[1.1667rem] w-full" />
          </th>
          <th
            scope="col"
            className="sticky top-0 z-10 table-cell border-b border-outline bg-gray-50 px-3 py-3.5 text-left backdrop-blur backdrop-filter"
          >
            <Skeleton className="h-[1.1667rem] w-full" />
          </th>
          <th
            scope="col"
            className="sticky top-0 z-10 hidden border-b border-outline bg-gray-50 px-3 py-3.5 text-left backdrop-blur backdrop-filter md:table-cell"
          />
        </tr>
      </thead>
      <tbody className="overflow-x-auto bg-white">
        {scheduledTimes.map(({ id }, index) => {
          const isLast = index === scheduledTimes.length - 1;
          return (
            <tr key={id}>
              <TableCell isLast={isLast} className="pl-4 pr-3 sm:pl-6 lg:pl-8">
                <Skeleton className="h-[1.1667rem] w-48 max-w-full" />
              </TableCell>
              <TableCell isLast={isLast}>
                <Skeleton className="h-[1.125rem] w-72 max-w-full" />
                <Skeleton className="mt-2 h-[1.125rem] w-72 max-w-full" />

                <div className="mt-2 md:hidden">
                  <Skeleton className="h-[1.75rem] w-24" />
                </div>
              </TableCell>
              <TableCell isLast={isLast} className="hidden pl-3 pr-4 text-right sm:pr-6 md:table-cell lg:pr-8">
                <Skeleton className="h-[2.375rem] w-32" />
              </TableCell>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

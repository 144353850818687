import { Skeleton } from 'atp-react-ui';

import { Container } from '@/_components';

import { PrepCourseCardSkeleton } from './prep-course-card';

const arr = new Array(10).fill(0).map((_, i) => i);

export function Loading({ withTitle = false }: { withTitle?: boolean }) {
  return (
    <section className="mt-8 flex flex-col gap-4">
      {withTitle && (
        <Container>
          <Skeleton className="h-[1.125rem] w-36" />
        </Container>
      )}
      <Container>
        <ul className="page-grid">
          {arr.map((i) => (
            <li key={i} className="page-grid-element">
              <PrepCourseCardSkeleton />
            </li>
          ))}
        </ul>
      </Container>
    </section>
  );
}
